import './Footer.css'

const Footer = () => (
  <footer>
    <a
      href='https://github.com/DarkHawk727/DarkHawk727-Personal-Website'
      className='link'
    >
      Created by Arjun Sarao
    </a>
  </footer>
)

export default Footer

// also made by Raj Shekhar
